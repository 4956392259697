'use strict';

function scrollToElement(target, time) {
  var offset;

  if ($(target).length > 0 && !$(this).hasClass('disabled')) {
    var offset = $(target).offset();
    $('html, body').animate({
      scrollTop: offset.top
    },
    time);
  }
}

$(document).ready(function () {
  $('[data-toggle="scroll-to"]').click(function () {
    var target = $(this).attr('href');

    if ($(target).length > 0 && !$(this).hasClass('disabled')) {
      scrollToElement(target, 600);
    }
    return false;
  });
});
