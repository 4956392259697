'use strict';

$(document).ready(function () {
  if(!is_touch_device()) {
    var imgParallaxFactor = {
      0: {
        img: .4,
      },
      1: {
        img: .2,
      }
    }

    $(window).on('mousemove', function(e) {
      var containerWidth = $(window).width();
      var containerHeight = $(window).height();

      var positionX = e.clientX;
      var positionY = e.clientY;

      var moveX = positionX / containerWidth * 100 - 50;
      var moveY = positionY / containerHeight * 100 - 50;

      $('.welcome-logo').each(function (index, item) {
        var $container = $(item);
        $container.css({transform: 'translate(' + (moveX * imgParallaxFactor[index]['img'] ) + 'px, ' + (moveY * imgParallaxFactor[index]['img'] ) + 'px)'});
      });
    });
  }
});
