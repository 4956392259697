$( document ).ready(function() {

  if($('#countdown-modal').length > 0) {
    var countdownEl = $('.countdown');
    var date = countdownEl.data('countdown');
    var countDownDate = new Date(date).getTime();
    var now = new Date().getTime();


    if(countDownDate - now > 0) {

      if($.cookie('countdown-1.1') !== 'close') {
        setTimeout(function(){
          $('#countdown-modal').modal('show');
        }, 2000);
      } else {
        $('.sticky-countdown').addClass('show');
      }

      $('#countdown-modal').on('hidden.bs.modal', function (e) {
        $.cookie('countdown-1.1', 'close', { path: '/' });
        $('.sticky-countdown').addClass('show');
      })
    }
  }
});
