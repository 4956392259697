$( document ).ready(function() {
  if($('.countdown').length > 0) {
    var countdownEl = $('.countdown');
    var date = countdownEl.data('countdown');
    var countDownDate = new Date(date).getTime();

    function addZero(nr) {
      if (nr < 10) {
        return '0' + nr;
      } else {
        return nr;
      }
    }

    function setCircleValue($element, value) {
      $left = $element.find('.countdown-item-value-border-left span')
      $right = $element.find('.countdown-item-value-border-right span')
      if (value < 50) {
        $right.css({'transform' : 'rotate(' + (value / 100 * 360) + 'deg)'});
        $left.css({'transform' : 'rotate(0)'});
      } else {
        $right.css({'transform' : 'rotate(180deg)'});
        $left.css({'transform' : 'rotate(' + (value / 100 * 360 - 180) + 'deg)'});
      }
    }

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      $('.countdown-item-value-nr-days').html(addZero(days));
      setCircleValue($('.countdown-item-value-days'), (30 - days)/30 * 100);

      $('.countdown-item-value-nr-hours').html(addZero(hours));
      setCircleValue($('.countdown-item-value-hours'), (24 - hours)/24 * 100);

      $('.countdown-item-value-nr-minutes').html(addZero(minutes));
      setCircleValue($('.countdown-item-value-minutes'), (60 - minutes)/60 * 100);

      $('.countdown-item-value-nr-seconds').html(addZero(seconds));
     setCircleValue($('.countdown-item-value-seconds'), (60 - seconds)/60 * 100);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        $('.article-header-online-course-container').removeClass('hidden');
        $('.article-header-online-course-container-countdown').addClass('hidden');
      }
    }, 1000);


  }
});
