$('[data-toggle="copy-text"]').click(function(e) {
  var $element = $(this);
  var text = $(this).data('text');

  var input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  var result = document.execCommand('copy');
  document.body.removeChild(input);
  $element.addClass('show-tooltip');

  setTimeout(function(){
    $element.removeClass('show-tooltip');
  }, 1000);
  e.preventDefault();
});