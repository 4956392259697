$( document ).ready(function() {
  function loadPrivatePlacementRecord() {
    var url = $('.private-placement-record-body-table').data('url');
    var timeout = $('.private-placement-record-body-table').data('timeout');

    $.ajax({
      type: "GET",
      url: url
    }).done(function(response) {
      var list = response.txs;
      $tbody = $('<tbody></tbody>');
      for (var i=0;i<list.length;i++) {
        var date = new Date(list[i].timestamp* 1000);
        var dateString = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-'  + String(date.getDate()).padStart(2, '0') + ' ' + date.getHours() + ':' + date.getMinutes();
        var hash = list[i].tx_hash.substring(0, 15) + '...' +  list[i].tx_hash.substring(list[i].tx_hash.length - 15);
        var $tr = $('<tr>' +
          '<td>' + dateString + '</td>' +
          '<td>' + list[i].send_eth_value + '</td>' +
          '<td>' + list[i].receive_mini_amount + '</td>' +
          '<td>' + list[i].fee + '</td>' +
          '<td><span class="text-primary">' + hash + '</span></td>' +
          '<td><span class="' + list[i].status + '">' + list[i].status + '</span></td>' +
        '</tr>');
        $tbody.append($tr);
      }

      $('.private-placement-record-body-table tbody').remove();
      $('.private-placement-record-body-table').append($tbody);
      $('.private-placement-record-body-loading').addClass('hidden');
      $('.private-placement-record-body-table').addClass('show');
      var page = $('.private-placement-record-body-table').attr('data-page');

      paginator({
        page: page,
        table: $('.private-placement-record-body-table')[0],
        box: $('.private-placement-record-body-pagination')[0],
        active_class: "active"
      });


      setTimeout(function(){
        loadPrivatePlacementRecord();
      }, timeout);
    })
    .fail(function(response) {
      setTimeout(function(){
        loadPrivatePlacementRecord();
      }, timeout);
    });

  }

  if($('.private-placement-record-body-table').length > 0) {
    loadPrivatePlacementRecord();
  };

});
