function startBaseHelper(context){

  context = context || 'body';

  /*******************************************************
   ***************************************** target blank
   *******************************************************/
  $('a.external', context).attr('target', '_blank');

  $('[data-toggle="disabled-click"]', context).click(function(e) {
    e.preventDefault();
  });


  /*******************************************************
   ***************************************** js player
   *******************************************************/
  const player = new Plyr('.js-player', {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
  });
}


$( document ).ready(function (e) {
  /*******************************************************
   ***************************************** base simple helper inti
   *******************************************************/
  $( window ).trigger('start');

  startBaseHelper();

  new FadeScrolling();

  /*******************************************************
  ***************************************** page hader
  *******************************************************/
   $(document).on('click', '[data-toggle="navbar"]', function(e){
     if($('body').hasClass('open-navbar')) {
       $(this).addClass('collapsed');
       $('.page-header-navbar').removeClass('in');
       $('body').removeClass('open-navbar');
     } else {
       $(this).removeClass('collapsed');
       $('.page-header-navbar').addClass('in');
       $('body').addClass('open-navbar');
     }
     e.preventDefault();
   });

   var prevScrollpos = window.pageYOffset;
   var pageNavbarVisible = true;

   function onScrollHeader (hideMenu) {
     var currentScrollPos = window.pageYOffset;
     if(currentScrollPos < 0) {
       return false;
     }
     if(hideMenu && currentScrollPos > 25) (prevScrollpos = prevScrollpos - 1);
     if (prevScrollpos > currentScrollPos && !pageNavbarVisible) {
       $('.page-header').removeClass('page-header-hide');
       pageNavbarVisible = true;
     } else if(prevScrollpos < currentScrollPos && pageNavbarVisible && currentScrollPos > 25){
       $('.page-header').addClass('page-header-hide');
       pageNavbarVisible = false;
     }
     prevScrollpos = currentScrollPos;

     var scroll = $(window).scrollTop();
     if (scroll >= 50) {
       $('.page-header').addClass('page-header-sticky');
     } else {
       $('.page-header').removeClass('page-header-sticky');
     }
   }

   window.onscroll = function() {
     onScrollHeader();
   }
   onScrollHeader(true);
});
