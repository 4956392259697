$( document ).ready(function() {
  var privatePlacementConfig = null;

  function loadPrivatePlacement() {
    var urlData = $('.private-placement-exchange-graph').data('data');
    var timeout = $('.private-placement-exchange-graph').data('timeout');

    $.ajax({
      type: "GET",
      url: urlData
    }).done(function(response) {

      var ratio = privatePlacementConfig[response.round - 1].ratio;
      $('.private-placement-exchange-header-ratio-swap').html(ratio);

      $('.private-placement-exchange-subheader-value').html(response.remaining_mini);

      var activeRound = privatePlacementConfig.length - response.round + 1;
      var firstLine = response.round - 1;
      var lastLine = privatePlacementConfig.length;

      if(activeRound > 10) {
        var lastLine = response.round - 1 + 8;
      }

      $content = $('<div class="private-placement-exchange-graph-content"></div>');
      for (var i = firstLine ; i < lastLine; i++) {
        var $line = $('<div class="private-placement-exchange-graph-line"><div class="private-placement-exchange-graph-line-value"><span>' + privatePlacementConfig[i].ethValue + 'ETH=' + (privatePlacementConfig[i].miniAmount) + 'MINI</span></div></div>');
        $content.append($line);
      }

      if(activeRound > 10) {
        var $line = $('<div class="private-placement-exchange-graph-line"><div class="private-placement-exchange-graph-line-value"><span>..........</span></div></div>');
        $content.append($line);
        var $line = $('<div class="private-placement-exchange-graph-line"><div class="private-placement-exchange-graph-line-value"><span>' + privatePlacementConfig[privatePlacementConfig.length - 1].ethValue + 'ETH=' + privatePlacementConfig[privatePlacementConfig.length - 1].miniAmount + 'MINI</span></div></div>');
        $content.append($line);
      }

      $('.private-placement-exchange-graph-content').remove();
      $('.private-placement-exchange-graph').append($content);

      var $remaining = $('<i class="private-placement-exchange-graph-line-value-remaining ' + (response.remaining_ration  < 20 ? 'right-text' : '') + '" style="width: ' + response.remaining_ration + '%"><span>' + response.remaining_ration + '%' + '</span></i>')
      $('.private-placement-exchange-graph-line:first-child .private-placement-exchange-graph-line-value').append($remaining);

      $('.private-placement-exchange-loading').addClass('hidden');
      $('.private-placement-exchange').addClass('show');

      setTimeout(function(){
        loadPrivatePlacement();
      }, timeout);
    });
  }

  if($('.private-placement-exchange-graph').length > 0) {
    var urlConfig = $('.private-placement-exchange-graph').data('config');
    $.ajax({
      type: "GET",
      url: urlConfig
    }).done(function(response) {
      privatePlacementConfig = response.Configs;
      loadPrivatePlacement();
    });
  };

});
